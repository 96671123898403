<template>
  <div class="select-lang-container">
    <div class="logo-container">
      <img src="/asset/image/gl-icon/logo-GL.svg">
    </div>
    <span class="select-lang-notice">
      Select the country and language to see the specific content to your location.
    </span>
    <div class="b-dd-group">
      <LangDropdown
        :options="countries"
        :downSVG="downSVG"
        @select-item="(e) => selectedCountry = e.desc"
        @auto-set-lang="autoSetLang()"
        :placeholder="countryPlaceholder"
      />
      <LangDropdown
        :options="languages"
        :downSVG="downSVG"
        @select-item="(e) => selectedLang = e.name"
        :placeholder="langPlaceholder"
      />
    </div>
    <a class="go-btn-container">
      <button @click="setLangCookie" class="go-btn">GO</button>
    </a>
  </div>
</template>

<script>
import LangDropdown from '../../components/lang/LangDropdown.vue'
import json from '../../components/lang/lang.json'
export default {
  layout: 'selectlang',
  components: {
    LangDropdown
  },
  data() {
    return {
      downSVG: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
      </svg>`,
      countries: [],
      languages: json,
      selectedCountry: 'Select Country/Region',
      selectedLang: 'Select Language'
    }
  },
  computed: {
    countryPlaceholder: function() {
      return `<span class="b-dd-text">${this.selectedCountry}</span> ${this.downSVG}`
    },
    langPlaceholder: function() {
      return `<span class="b-dd-text">${this.selectedLang}</span> ${this.downSVG}`
    }
  },
  created() {
    this.getCountryList()
  },
  methods: {
    getCountryList: async function() {
      await this.$store.dispatch('lang/fetchCountryList')
      const countryList = JSON.parse(JSON.stringify(this.$store.state.lang.countryList))
      this.countries = countryList
    },
    setLangCookie: function() {
      if (this.selectedCountry !== 'Select Country/Region' && this.selectedLang !== 'Select Language') {
        const country = this.countries.find(country => country.desc === this.selectedCountry)
        let countryId = 0
        if (country) {
          countryId = country.id
        }
        const language = this.languages.find(lang => lang.name === this.selectedLang)
        let langCookie = 0
        if (language) {
          langCookie = language.code
        }
        if (countryId && langCookie) {
          this.$cpCookie.set('revampLang', langCookie)
          this.$cpCookie.set('default_country', countryId)
          const redirectPath = this.$cpCookie.get('redirectPath')
          window.location.href = redirectPath
        } else {
          alert('Please select country / language')
        }
      } else {
        alert('Please select country / language')
      }
    },
    autoSetLang: function() {
      const country = this.countries.find(country => country.desc === this.selectedCountry)
      if (!country) {
        this.selectedLang = 'English'
        return
      }
      const lang = this.languages.find(language => language.code === country.defaultLocale)
      if (!lang) {
        this.selectedLang = 'English'
        return
      }
      this.selectedLang = lang.name
    }
  }
}
</script>

<style lang="scss">
.select-lang-container {
  @media screen and (min-width: 320px) {
    display: flex;
    flex-direction: column;
    width: 350px;
    align-items: center;
    justify-content: center;
    margin: auto;
    gap: 25px;
    height: 60vh;
  }
  @media screen and (min-width: 768px) {
    width: 675px;
    height: 83vh;
  }

  .logo-container {
    img {
      width: 164px;
      object-fit: contain;
    }
  }

  .select-lang-notice {
    text-align: center;
    font-size: 18px;
    @media screen and (min-width: 1024px) {
      font-size: 20px;
    }
  }

  .b-dd-group {
    display: flex;
    flex-direction: column;
    gap: 25px;
    z-index: 100;
    @media screen and (min-width: 768px) {
      flex-direction: row;
    }
  }

  .selectbox-container {
    display: flex;
    flex-direction: column;
    gap: 25px;
    @media screen and (min-width: 768px) {
      flex-direction: row;
    }
  }

  .b-dd-wrapper {
    width: 294px;
    @media screen and (min-width: 768px) {
      width: 200px;
    }
  }

  .selectlang-dd {
    width: 100%;
    border: solid 1px #CECECE;
    border-radius: 20px;
    font-size: 14px;
    height: 40px;
    color: #333333;
    box-shadow: 0px 3px 6px #00000029;
  }

  .b-dd-text {
    position: absolute;
    top: 9px;
    left: 15px;
  }

  .go-btn-container {
    z-index: 99;
    .go-btn {
      border: none;
      width: 294px;
      background-color: #0EA5BF;
      height: 40px;
      color: #FFFFFF;
      border-radius: 20px;
      font-size: 14px;
      margin-top: 14px;
      @media screen and (min-width: 768px) {
        width: 176px;
        margin-top: 50px;
      }
    }
  }
}
</style>
